import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerDetailsService {
  private data = new BehaviorSubject('');
  data$ = this.data.asObservable();

  // Add a new subject to track tab state changes
  private tabState = new BehaviorSubject<{
    activitiesActive: boolean;
    attributeActive: boolean;
  }>({
    activitiesActive: true,
    attributeActive: false,
  });
  tabState$ = this.tabState.asObservable();

  constructor() {}

  changeData(data: string) {
    this.data.next(data);

    // Update tab state based on sidebar selection
    if (data === 'activities') {
      this.tabState.next({
        activitiesActive: true,
        attributeActive: false,
      });
    } else if (data === 'attribute') {
      this.tabState.next({
        activitiesActive: false,
        attributeActive: true,
      });
    }
  }

  clearData(): void {
    this.data.next(''); // Clear the data when sidebar should be hidden
  }
}
